import PropTypes from 'prop-types';
import React from 'react';
import AcceptOperativeRequestAction from './AcceptOperativeRequestAction';
import RejectOperativeRequestAction from './RejectOperativeRequestAction';

const OperativeRequestsActions = ({ operativeRequestId = null, onClose }) => (
  <>
    <AcceptOperativeRequestAction
      operativeRequestId={operativeRequestId}
      onClose={onClose}
    />
    <RejectOperativeRequestAction
      operativeRequestId={operativeRequestId}
      onClose={onClose}
    />
  </>
);

OperativeRequestsActions.propTypes = {
  operativeRequestId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

OperativeRequestsActions.defaultProps = {
  operativeRequestId: null,
};

export default OperativeRequestsActions;
