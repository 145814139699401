import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const CommissionForm = ({ operativeRequest, setParameters }) => {
  const [commission, _setCommission] = useState();
  const setCommission = (value) => {
    _setCommission(value);
    setParameters([
      {
        key: 'commission',
        value,
      },
    ]);
  };

  return (
    <>
      <Typography variant="h4" sx={{ my: 2 }} textAlign="center">
        {operativeRequest.operativeRequestType?.name}
      </Typography>
      <NumericFormat
        id="change-commission"
        name="monthlyRate"
        value={commission}
        onValueChange={(values) => setCommission(values.value)}
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={0}
        fixedDecimalScale
        valueIsNumericString
        prefix="$"
        customInput={TextField}
      />
    </>
  );
};

CommissionForm.propTypes = {
  operativeRequest: PropTypes.shape({
    id: PropTypes.string,
    operativeRequestType: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    operativeRequestManagers: PropTypes.arrayOf({
      id: PropTypes.string,
      invoice: PropTypes.shape({
        id: PropTypes.string,
        folio: PropTypes.string,
      }),
    }),
  }).isRequired,
  setParameters: PropTypes.func.isRequired,
};

export default CommissionForm;
