import { useMutation } from '@apollo/client';
import { useSnackBars } from '@fingo/lib/hooks';
import { CREATE_OPERATIVE_REQUEST } from '../graphql/operative-requests';

const useRaiseOperativeRequest = () => {
  const { addAlert } = useSnackBars();
  return useMutation(CREATE_OPERATIVE_REQUEST, {
    onCompleted: () => {
      addAlert({
        id: 'success-operative-request',
        color: 'success',
        message: '¡Solicitud enviada exitosamente!',
      });
    },
    onError: ({ message }) => {
      addAlert({
        id: 'success-operative-request',
        color: 'error',
        message,
      });
    },
  });
};

export default useRaiseOperativeRequest;
