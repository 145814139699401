import SupportAgent from '@mui/icons-material/SupportAgent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { useAnchorElementState, useBooleanState } from '@fingo/lib/hooks';
import SupportRequestMenu from '../menus/SupportRequestMenu';

const RaiseOperativeRequestIconButton = ({
  documentIds,
  documentType = 'invoice',
}) => {
  const [hovered, , setTrue, setFalse] = useBooleanState(false);
  const [anchor, openAnchor, closeAnchor] = useAnchorElementState();

  return (
    <>
      <Tooltip title="¡Ayuda!">
        <span>
          <IconButton
            onClick={openAnchor}
            disabled={!documentIds?.length}
            onMouseOver={setTrue}
            onMouseLeave={setFalse}
            color="primary"
            sx={{
              transition: 'transform 0.5s ease-in-out',
              transform: 'rotate(0deg)',
              ...(hovered && { transform: 'scale(1.2) rotate(10deg)' }),
            }}
          >
            <SupportAgent />
          </IconButton>
        </span>
      </Tooltip>
      {anchor && (
        <SupportRequestMenu
          anchor={anchor}
          onClose={closeAnchor}
          documentIds={documentIds}
          documentType={documentType}
        />
      )}
    </>
  );
};

RaiseOperativeRequestIconButton.propTypes = {
  documentType: PropTypes.string,
  documentIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

RaiseOperativeRequestIconButton.defaultProps = {
  documentIds: [],
  documentType: 'invoice',
};

export default RaiseOperativeRequestIconButton;
