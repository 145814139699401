import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import useRaiseOperativeRequest from '../../hooks/useRaiseOperativeRequest';

const SupportRequestDialog = ({
  open,
  onClose,
  documentIds,
  documentType,
  requestType,
}) => {
  const [comment, setComment] = useState();

  const [createOperativeRequest, { loading }] = useRaiseOperativeRequest();

  const onSubmit = useCallback(() => {
    createOperativeRequest({
      variables: {
        documentType,
        requestType: requestType.code,
        documentIds,
        comment,
      },
      onCompleted: () => {
        onClose();
      },
    });
  }, [comment]);

  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title={`${requestType.name} para ${documentIds.length} documentos`}
      fullWidth
      dialogActionButton={(
        <LoadingButton
          onClick={onSubmit}
          loading={loading}
          color="primary"
          variant="contained"
          size="small"
        >
          Confirmar
        </LoadingButton>
      )}
    >
      <TextField
        id="operative-request-reason"
        label="Motivo de la solicitud"
        variant="outlined"
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        multiline
        rows={3}
        fullWidth
      />
    </FingoDialog>
  );
};

SupportRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  requestType: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  documentIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default SupportRequestDialog;
