import React, { useMemo, useState } from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import Typography from '@mui/material/Typography';
import { OPERATIVE_REQUESTS_COLUMNS } from '../constants/operative-requests';
import { OPERATIVE_REQUESTS } from '../graphql/operative-requests';
import OperativeRequestActions from './actions/OperativeRequestsActions';
import OperativeRequestInvoices from './OperativeRequestInvoices';
import OperativeRequestPurchaseOrders from './OperativeRequestPurchaseOrders';
import { getContenTypeModel } from '../helpers/operative-requests';

const OperativeRequests = () => {
  const [selectedOperativeRequestIds, setSelectedOperativeRequestIds] = useState();

  return (
    <FingoMainView
      id="operative-requests"
      query={OPERATIVE_REQUESTS}
      queryCustomVariables={{
        status: 'PENDING',
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        actions: OperativeRequestActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Solicitudes operacionales',
        },
        table: {
          columns: OPERATIVE_REQUESTS_COLUMNS,
          includeHeaders: [
            'id',
            'requester',
            'operativeRequestType',
            'documentType',
            'comment',
          ],
          checkboxSelection: true,
          collapsible: true,
          collapseComponent: ({ row }) => {
            const documentIds = useMemo(
              () => row.operativeRequestManagers.map(({ objectId }) => objectId),
              [row],
            );
            if (getContenTypeModel(row) === 'invoice') {
              return <OperativeRequestInvoices documentIds={documentIds} />;
            }
            return <OperativeRequestPurchaseOrders documentIds={documentIds} />;
          },
          isRowSelectable: () => true,
          isRowCollapsible: () => true,
          disableSelectionOnClick: true,
          onSelectionModelChange: (ids) => {
            setSelectedOperativeRequestIds(ids.slice(-1));
          },
          selectionModel: selectedOperativeRequestIds,
          noRowsMessage: () => (
            <Typography>
              ¡Felicidades! No hay solicitudes pendientes.
            </Typography>
          ),
          rowsPerPageOptions: [15, 20, 50],
        },
        actions: {
          operativeRequestId: selectedOperativeRequestIds?.[0],
          onClose: () => setSelectedOperativeRequestIds([]),
        },
      }}
    />
  );
};

export default OperativeRequests;
