import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { REQUEST_TYPE_TO_EMOJI } from '../../constants/operative-requests';

const OperativeRequestTypeCell = ({ operativeRequestType }) => {
  const { code, name } = operativeRequestType;
  const emoji = useMemo(() => REQUEST_TYPE_TO_EMOJI[code], [code]);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={2}
    >
      <Typography variant="h4">{emoji}</Typography>
      <Typography variant="h6" width={1}>{name}</Typography>
    </Stack>
  );
};

OperativeRequestTypeCell.propTypes = {
  operativeRequestType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
};

export default OperativeRequestTypeCell;
