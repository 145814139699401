import { useState } from 'react';

const useRateRows = (rates) => {
  const [rateRows, setRateRowsState] = useState(rates);
  const setRateRows = (event) => {
    setRateRowsState((prev) => {
      const currentRows = [...prev];
      const updatedRow = currentRows.find((iterRow) => iterRow.id === event.target.id);
      const index = currentRows.indexOf(updatedRow);
      updatedRow[event.target.name] = event.target.value;
      currentRows[index] = updatedRow;
      return currentRows;
    });
  };
  const setDateRow = (row, newDate) => {
    setRateRowsState((prev) => {
      const currentRows = [...prev];
      const updatedRow = currentRows.find((iterRow) => iterRow.id === row.id);
      const index = currentRows.indexOf(updatedRow);
      updatedRow.dateToPay = newDate;
      currentRows[index] = updatedRow;
      return currentRows;
    });
  };
  const setAllRows = (firstRow) => {
    const updatedRows = rateRows.map((row, index) => {
      if (index === 0) return row;
      return {
        ...row,
        monthlyRate: firstRow.monthlyRate,
        retentionRate: firstRow.retentionRate,
        defaultRate: firstRow.defaultRate,
        dateToPay: firstRow.dateToPay,
      };
    });
    setRateRowsState(updatedRows);
  };
  const allAreRowsEqual = rateRows.every(
    ({ dateToPay, monthlyRate, retentionRate, defaultRate }) => (
      dateToPay === rateRows[0]?.dateToPay
        && monthlyRate === rateRows[0]?.monthlyRate
        && retentionRate === rateRows[0]?.retentionRate
        && defaultRate === rateRows[0]?.defaultRate

    ),
  );
  return [rateRows, setRateRows, setDateRow, setAllRows, allAreRowsEqual];
};

export default useRateRows;
