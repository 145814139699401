import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cancel from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import RejectOperativeRequestDialog from '../dialogs/RejectOperativeRequestDialog';

const RejectOperativeRequestAction = ({
  operativeRequestId = null,
  onClose,
}) => {
  const [
    openRejectOperativeRequestDialog,
    setOpenRejectOperativeRequestDialog,
  ] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setOpenRejectOperativeRequestDialog(true)}
        disabled={!operativeRequestId}
      >
        <Cancel color="error" />
      </IconButton>
      {openRejectOperativeRequestDialog && (
        <RejectOperativeRequestDialog
          open={openRejectOperativeRequestDialog}
          onClose={() => {
            setOpenRejectOperativeRequestDialog(false);
            onClose();
          }}
          operativeRequestId={operativeRequestId}
        />
      )}
    </>
  );
};

RejectOperativeRequestAction.propTypes = {
  operativeRequestId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

RejectOperativeRequestAction.defaultProps = {
  operativeRequestId: null,
};

export default RejectOperativeRequestAction;
