/* eslint-disable max-len */
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { getRatesFromOperativeRequest } from '../../helpers/operative-requests';
import { useRateColumns, useRateRows } from '../../hooks';

const RatesForm = ({ operativeRequest, setParameters }) => {
  const isInvoice = useMemo(
    () => operativeRequest.operativeRequestManagers[0]?.invoice,
    [operativeRequest],
  );
  const rates = getRatesFromOperativeRequest(operativeRequest, isInvoice);
  const [rateRows, setRateRows, setDateRow, setAllRows, allAreRowsEqual] = useRateRows(rates);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked && rateRows.length > 0) {
      const firstRow = rateRows[0];
      setAllRows(firstRow);
    }
  };

  useEffect(() => {
    setParameters(
      rateRows.map((row) => ({
        key: row.id,
        value: {
          monthlyRate: row.monthlyRate,
          retentionRate: row.retentionRate,
          defaultRate: row.defaultRate,
          dateToPay: row.dateToPay.format('YYYY-MM-DD'),
        },
      })),
    );
  }, [rateRows]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#f9f9f9"
        padding={1}
        marginBottom={1}
        borderRadius={1}
        marginTop={-3}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={allAreRowsEqual}
              onChange={handleCheckboxChange}
              sx={{
                color: '#E62E66',
                '&.Mui-checked': {
                  color: '#E62E66',
                },
              }}
            />
          )}
          label="Usar misma condición para todas"
        />
      </Box>
      <FingoDataGrid
        rows={rateRows}
        columns={useRateColumns(setRateRows, setDateRow)}
        hideFooter
        sx={{ width: 600 }}
      />
    </>
  );
};

RatesForm.propTypes = {
  operativeRequest: PropTypes.shape({
    id: PropTypes.string,
    operativeRequestType: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    operativeRequestManagers: PropTypes.arrayOf({
      id: PropTypes.string,
      invoice: PropTypes.shape({
        id: PropTypes.string,
        folio: PropTypes.string,
      }),
    }),
  }).isRequired,
  setParameters: PropTypes.func.isRequired,
};

export default RatesForm;
