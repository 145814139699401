import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { OPERATIVE_REQUEST_PURCHASE_ORDERS } from '../graphql/operative-requests';

const OperativeRequestPurchaseOrders = ({ documentIds }) => {
  const { data, loading } = useQuery(OPERATIVE_REQUEST_PURCHASE_ORDERS, {
    variables: { id_In: documentIds },
  });

  const purchaseOrders = useMemo(
    () => Object.values(data || [])?.[0]?.edges?.map((doc) => doc?.node) || [],
    [data],
  );

  return (
    <FingoTable
      rows={purchaseOrders}
      columns={OPERATIVE_REQUEST_PURCHASE_ORDERS}
      loading={loading}
      includeHeaders={[
        'orderNumber',
        'totalAmount',
        'company_Name',
        'purchaser_Name',
        'orderingStatus',
      ]}
      pagination={false}
    />
  );
};

OperativeRequestPurchaseOrders.propTypes = {
  documentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default OperativeRequestPurchaseOrders;
