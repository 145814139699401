import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackBars } from '@fingo/lib/hooks';
import {
  ACCEPT_OPERATIVE_REQUEST,
  GET_OPERATIVE_REQUEST,
  OPERATIVE_REQUESTS,
} from '../../graphql/operative-requests';
import { SimpleForm } from '../forms';
import { OPERATIVE_REQUEST_TYPE_TO_COMPONENT } from '../../constants/operative-requests';

const AcceptOperativeRequestDialog = ({
  open,
  onClose,
  operativeRequestId,
}) => {
  const [acceptRequestParams, setAcceptRequestParams] = useState([]);
  const { addAlert } = useSnackBars();
  const [acceptOperativeRequest, { loading }] = useMutation(
    ACCEPT_OPERATIVE_REQUEST,
    {
      refetchQueries: [OPERATIVE_REQUESTS],
      variables: {
        operativeRequestId,
        parameters: acceptRequestParams,
      },
      onCompleted: () => {
        onClose();
        setAcceptRequestParams([]);
        addAlert({
          id: 'accept-operative-request',
          color: 'success',
          message: '¡Solicitud aceptada!',
        });
      },
      onError: ({ message }) => {
        addAlert({
          id: 'success-operative-request',
          color: 'error',
          message: `Error: ${message}`,
        });
      },
    },
  );

  const { data, loading: loadingQuery } = useQuery(GET_OPERATIVE_REQUEST, {
    variables: { operativeRequestId },
  });

  // variables
  const operativeRequest = data ? data.getOperativeRequest : {};
  const FormComponent = isEmpty(operativeRequest)
    ? SimpleForm
    : OPERATIVE_REQUEST_TYPE_TO_COMPONENT[
      operativeRequest.operativeRequestType.code
    ];

  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title={`Aceptar solicitud N°${operativeRequestId}`}
      dialogActionButton={(
        <LoadingButton
          variant="contained"
          size="small"
          onClick={acceptOperativeRequest}
          loading={loading}
        >
          Aceptar
        </LoadingButton>
      )}
      paddingContent={3}
    >
      {loadingQuery ? (
        <CircularProgress />
      ) : (
        <FormComponent
          operativeRequest={operativeRequest}
          setParameters={setAcceptRequestParams}
        />
      )}
    </FingoDialog>
  );
};

AcceptOperativeRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  operativeRequestId: PropTypes.string.isRequired,
};

export default AcceptOperativeRequestDialog;
