import moment from 'moment';

export const getContenTypeModel = (operativeRequest) => {
  const { operativeRequestManagers } = operativeRequest;
  return operativeRequestManagers[0].contentType.model;
};

export const getRatesFromOperativeRequest = (operativeRequest, isInvoice) => {
  let rates;
  if (isInvoice) {
    rates = operativeRequest.operativeRequestManagers.map(({ invoice }) => ({
      id: invoice.id,
      docNumber: invoice.folio,
      dateToPay: moment(invoice.dateToPay),
      monthlyRate: invoice.offer
        ? invoice.offer.monthlyRate
        : invoice.preoffer.monthlyRate,
      retentionRate: invoice.offer
        ? invoice.offer.retentionRate
        : invoice.preoffer.retentionRate,
      defaultRate: invoice.offer
        ? invoice.offer.defaultRate
        : invoice.preoffer.defaultRate,
    }));
    return rates;
  }
  rates = operativeRequest.operativeRequestManagers.map(
    ({ purchaseOrder }) => ({
      id: purchaseOrder.id,
      docNumber: purchaseOrder.orderNumber,
      dateToPay: moment(purchaseOrder.orderingoffer?.invoiceIssuedDate),
      monthlyRate: purchaseOrder.orderingoffer
        ? purchaseOrder.orderingoffer.orderingMonthlyRate
        : purchaseOrder.orderingsimulation.orderingMonthlyRate,
      retentionRate: purchaseOrder.orderingoffer
        ? purchaseOrder.orderingoffer.orderingRetentionRate
        : purchaseOrder.orderingsimulation.orderingRetentionRate,
      defaultRate: purchaseOrder.orderingoffer
        ? purchaseOrder.orderingoffer.orderingDefaultRate
        : purchaseOrder.orderingsimulation.orderingDefaultRate,
    }),
  );
  return rates;
};
