import { useQuery } from '@apollo/client';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { OPERATIVE_REQUEST_INVOICES } from '../graphql/operative-requests';
import { OPERATIVE_REQUEST_INVOICES_COLUMNS } from '../constants/operative-requests';

const OperativeRequestInvoices = ({ documentIds }) => {
  const { data, loading } = useQuery(OPERATIVE_REQUEST_INVOICES, {
    variables: { id_In: documentIds },
  });

  const invoices = useMemo(
    () => Object.values(data || [])?.[0]?.edges?.map((doc) => doc?.node) || [],
    [data],
  );

  return (
    <FingoTable
      rows={invoices}
      columns={OPERATIVE_REQUEST_INVOICES_COLUMNS}
      loading={loading}
      includeHeaders={[
        'folio',
        'amountWithIva',
        'company_Name',
        'receiver_sortable',
        'status',
      ]}
      pagination={false}
    />
  );
};

OperativeRequestInvoices.propTypes = {
  documentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default OperativeRequestInvoices;
