import { useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import { MAIL_LOG } from '@fingo/lib/graphql';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DATA_GRID_COLUMNS, HEADER_TITLE } from '../../constants/rejection-mail';
import RejectionMailActions from './rejection-mailing/RejectionFilters';

const Mailing = ({ extraFilters }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState();
  const [mailFilters, setMailFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [mails, setMails] = useState([]);
  const { loading } = useQuery(
    MAIL_LOG,
    { variables: {
      first: pageSize,
      offset: page * pageSize,
      ...mailFilters,
      ...extraFilters,
    },
    onCompleted: ({ mailLog }) => {
      setRowCount(mailLog.totalCount);
      setMails(mailLog.edges.map((row) => row.node));
    },
    },
  );
  return (
    <>
      <PaperHeader viewTitle={HEADER_TITLE} />
      <RejectionMailActions
        mailFilters={mailFilters}
        selectionModel={selectedRows}
        setMailFilters={setMailFilters}
      />
      <FingoDataGrid
        disableSelectionOnClick
        disableVirtualization
        rows={mails}
        columns={DATA_GRID_COLUMNS}
        loadingWithSkeleton={loading}
        getRowId={(row) => row.id} // verify
        headerHeight={70}
        density="compact"
        pagination
        paginationMode="server"
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={rowCount}
        rowHeight={100}
        checkboxSelection
        onSelectionModelChange={(ids) => setSelectedRows(ids)}
        isRowSelectable={({ row }) => row.status.status !== 'COMPLETED'}
        selectionModel={selectedRows}
      />
    </>
  );
};

Mailing.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  extraFilters: PropTypes.object,
};

Mailing.defaultProps = {
  extraFilters: {},
};

export default Mailing;
