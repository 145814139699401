import { useMutation } from '@apollo/client';
import ValidationTextField from '@fingo/lib/components/inputs/ValidationTextFieldInput';
import { MAIL_LOG, SEND_MAILS } from '@fingo/lib/graphql';
import Mail from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';

const EmailInput = ({ row, to, disabled }) => {
  const [sendMails] = useMutation(SEND_MAILS, { refetchQueries: [MAIL_LOG] });
  const handleClick = (selectedRow) => () => {
    sendMails({ variables: {
      mailLogIds: [selectedRow.id],
    } });
  };
  return (
    <Stack direction="row" alignItems="center" width="100%">
      <ValidationTextField
        disabled
        value={to}
        fullWidth
      />
      <IconButton
        color="primary"
        onClick={handleClick(row)}
        disabled={disabled || !to}
        sx={{ mx: 1, justifySelf: 'center' }}
      ><Mail />
      </IconButton>
    </Stack>
  );
};
EmailInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default EmailInput;
