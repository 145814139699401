import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { OPERATIVE_REQUEST_TYPES } from '../graphql/operative-requests';

const CODES = [
  'MOVE_TO_PENDINGRATIFICATION',
  'MOVE_TO_PENDINGDOCUMENTSREVIEW',
  'CHANGE_OPERATION_CONDITIONS',
  'CHANGE_TO_INTERNAL_CESION',
  'UNDO_CONCILIATION',
  'CHANGE_COMMISSION',
  'MOVE_TO_EVALUATING',
];

const useOperativeRequestTypes = () => {
  const { data } = useQuery(OPERATIVE_REQUEST_TYPES, {
    variables: { code_In: CODES },
  });

  const operativeRequestTypes = useMemo(
    () => Object.values(data || [])?.[0]?.edges?.map((doc) => doc?.node) || [],
    [data],
  );

  return operativeRequestTypes;
};

export default useOperativeRequestTypes;
