import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircle from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import AcceptOperativeRequestDialog from '../dialogs/AcceptOperativeRequestDialog';

const AcceptOperativeRequestAction = ({
  operativeRequestId = null,
  onClose,
}) => {
  const [
    openAcceptOperativeRequestDialog,
    setAcceptOpenOperativeRequestDialog,
  ] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setAcceptOpenOperativeRequestDialog(true)}
        disabled={!operativeRequestId}
      >
        <CheckCircle color="success" />
      </IconButton>
      {openAcceptOperativeRequestDialog && (
        <AcceptOperativeRequestDialog
          open={openAcceptOperativeRequestDialog}
          onClose={() => {
            setAcceptOpenOperativeRequestDialog(false);
            onClose();
          }}
          operativeRequestId={operativeRequestId}
        />
      )}
    </>
  );
};

AcceptOperativeRequestAction.propTypes = {
  operativeRequestId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

AcceptOperativeRequestAction.defaultProps = {
  operativeRequestId: null,
};

export default AcceptOperativeRequestAction;
