import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import StatusTrafficLight from '@fingo/lib/components/dataDisplay/StatusTrafficLight';
import emailTranslator from '@fingo/lib/helpers/email-translator';
import Block from '@mui/icons-material/Block';
import EmailInput from '../components/mailing/rejection-mailing/EmailInput';
import AttachmentCell from './AttachmentCell';

export const DATA_GRID_COLUMNS = [
  {
    field: 'status',
    headerName: 'Estado',
    renderCell: ({ value }) => (
      <StatusTrafficLight value={value.status} />
    ),
    flex: 0.25,
  },
  {
    field: 'mailParameter_Mail_EmailName',
    headerName: 'Tipo',
    renderCell: (params) => {
      const { value } = params;
      if (value && value.mail && value.mail.emailName) {
        return <Typography fontWeight="bold">{emailTranslator(value.mail.emailName)}</Typography>;
      }
      return <Typography fontWeight="bold">N/A</Typography>;
    },
  },
  {
    field: 'masterEntity',
    headerName: 'Empresa',
    renderCell: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <Typography>
          {row.masterEntity?.name} ({row.masterEntity?.displayNationalIdentifier})
        </Typography>
        {row.masterEntity?.riskBlacklist && (
          <Tooltip title="Empresa en la Blacklist">
            <Block loading sx={{ width: 15, height: 15 }} color="error" />
          </Tooltip>
        )}
      </Stack>
    ),
    flex: 1,
  },
  {
    field: 'attachment',
    type: 'string',
    headerName: 'Archivo adjunto',
    renderCell: ({ row }) => <AttachmentCell row={row} />,
    flex: 0.5,
  },
  {
    field: 'to',
    headerName: 'Acciones',
    renderCell: ({ value, row }) => (
      <EmailInput
        to={value}
        row={row}
        disabled={row.status.status === 'COMPLETED'}
      />
    ),
    flex: 1,
  },
];

export const HEADER_TITLE = 'Gestor de emails';
