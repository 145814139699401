/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { MoneyFields } from '@fingo/lib/graphql/moneyFields';
import { FileFields } from '@fingo/lib/graphql/fileField';

export const OPERATIVE_REQUESTS = gql`
  query operativeRequests($first: Int, $offset: Int, $status: String) {
    operativeRequests(first: $first, offset: $offset, status: $status) {
      totalCount
      edges {
        node {
          id
          comment
          operativeRequestType {
            id
            name
            code
          }
          requester {
            id
            firstName
            lastName
            completeName @client
            picture {
              ...FileFields
            }
          }
          operativeRequestManagers {
            id
            contentType {
              id
              appLabel
              model
            }
            objectId
          }
        }
      }
    }
  }
  ${FileFields}
`;

export const OPERATIVE_REQUEST_TYPES = gql`
  query OperativeRequestTypes($code_In: [String]) {
    operativeRequestTypes(code_In: $code_In) {
      edges {
        node {
          id
          code
          name
          active
        }
      }
    }
  }
`;

export const OPERATIVE_REQUEST_INVOICES = gql`
  query OperativeRequestInvoices($id_In: [String]) {
    invoices(id_In: $id_In) {
      totalCount
      edges {
        node {
          id
          folio
          amountWithIva {
            ...MoneyFields
          }
          status
          receiver {
            name
            id
            rut
            displayNationalIdentifier
          }
          company {
            id
            masterEntity {
              id
              name
              rut
              displayNationalIdentifier
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
`;

export const OPERATIVE_REQUEST_PURCHASE_ORDERS = gql`
  query OperativeRequestPurchaseOrders($id_In: [String]) {
    purchaseOrders(id_In: $id_In) {
      totalCount
      edges {
        node {
          id
          orderNumber
          status
          totalAmount {
            ...MoneyFields
          }
          company {
            id
            masterEntity {
              id
              name
              rut
              displayNationalIdentifier
            }
          }
          purchaser {
            name
            id
            rut
            displayNationalIdentifier
          }
          orderingoffer {
            id
            orderingFinancedAmount {
              ...MoneyFields
            }
          }
          orderingsimulation {
            id
            orderingFinancedAmount {
              ...MoneyFields
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
`;

export const CREATE_OPERATIVE_REQUEST = gql`
  mutation CreateOperativeRequest(
    $requestType: String!
    $documentType: String!
    $documentIds: [Int]!
    $comment: String!
  ) {
    createOperativeRequest(
      requestType: $requestType
      documentType: $documentType
      documentIds: $documentIds
      comment: $comment
    ) {
      operativeRequest {
        id
      }
    }
  }
`;

export const GET_OPERATIVE_REQUEST = gql`
  query GetOperativeRequest($operativeRequestId: ID!) {
    getOperativeRequest(operativeRequestId: $operativeRequestId) {
      id
      status
      comment
      operativeRequestType {
        id
        shortName
        name
        code
      }
      operativeRequestManagers {
        id
        invoice {
          ... on InvoiceType {
            id
            folio
            dateToPay
            preoffer {
              id
              monthlyRate
              defaultRate
              retentionRate
            }
            offer {
              id
              monthlyRate
              defaultRate
              retentionRate
            }
            operation {
              id
              commission {
                ...MoneyFields
              }
            }
          }
        }
        purchaseOrder {
          ... on OrderingPurchaseOrderType {
            id
            orderNumber
            status
            orderingsimulation {
              id
              invoiceIssuedDate
              orderingMonthlyRate
              orderingDefaultRate
              orderingRetentionRate
            }
            orderingoffer {
              id
              invoiceIssuedDate
              orderingMonthlyRate
              orderingDefaultRate
              orderingRetentionRate
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
`;

export const REJECT_OPERATIVE_REQUEST = gql`
  mutation RejectOperativeRequest(
    $operativeRequestId: ID!
    $parameters: [GenericParameterType!]!
  ) {
    rejectOperativeRequest(
      operativeRequestId: $operativeRequestId
      parameters: $parameters
    ) {
      operativeRequest {
        id
        status
      }
    }
  }
`;

export const ACCEPT_OPERATIVE_REQUEST = gql`
  mutation AcceptOperativeRequest(
    $operativeRequestId: ID!
    $parameters: [GenericParameterType!]!
  ) {
    acceptOperativeRequest(
      operativeRequestId: $operativeRequestId
      parameters: $parameters
    ) {
      operativeRequest {
        id
        status
      }
    }
  }
`;
