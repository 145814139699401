import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import { useSnackBars } from '@fingo/lib/hooks';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  OPERATIVE_REQUESTS,
  REJECT_OPERATIVE_REQUEST,
} from '../../graphql/operative-requests';

const RejectOperativeRequestDialog = ({
  open,
  onClose,
  operativeRequestId,
}) => {
  const [comment, setComment] = useState();
  const { addAlert } = useSnackBars();
  const [rejectOperativeRequest, { loading }] = useMutation(
    REJECT_OPERATIVE_REQUEST,
    {
      refetchQueries: [OPERATIVE_REQUESTS],
      variables: {
        operativeRequestId,
        parameters: [
          {
            key: 'comment',
            value: comment,
          },
        ],
      },
      onCompleted: () => {
        setComment(null);
        onClose();
        addAlert({
          id: 'reject-operative-request-success',
          color: 'success',
          message: '¡Solicitud rechazada!',
        });
      },
      onError: ({ message }) => {
        addAlert({
          id: 'reject-operative-request-error',
          color: 'error',
          message,
        });
      },
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title={`Rechazar solicitud N°${operativeRequestId}`}
      fullWidth
      dialogActionButton={(
        <LoadingButton
          onClick={rejectOperativeRequest}
          loading={loading}
          color="primary"
          variant="contained"
          size="small"
        >
          Confirmar
        </LoadingButton>
      )}
    >
      <TextField
        id="operative-request-reason"
        label="Motivo del rechazo"
        variant="outlined"
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        multiline
        rows={3}
        fullWidth
      />
    </FingoDialog>
  );
};

RejectOperativeRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  operativeRequestId: PropTypes.string.isRequired,
};

export default RejectOperativeRequestDialog;
