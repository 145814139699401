import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import SupportAgent from '@mui/icons-material/SupportAgent';
import { FingoPaper } from '@fingo/lib/components/layout';
import OperativeRequests from '../components/OperativeRequests';

export const operativeRequestsTabs = (user) => [
  {
    id: 'operative-requests',
    label: 'support-operative-requests',
    path: 'available',
    component: <OperativeRequests />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'support.view_operativerequest'),
  },
];

export const supportRoutes = (user) => [
  {
    id: 'requests',
    label: 'Solicitudes',
    path: 'operative-requests',
    icon: <SupportAgent />,
    component: <FingoPaper tabs={operativeRequestsTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'support.view_operativerequest'),
  },
];
