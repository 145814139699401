import { GridEditDateCell } from '@fingo/lib/components/dataGridCells';
import TextField from '@mui/material/TextField';
import React from 'react';

const useRateColumns = (setRateRows, setDateRow) => {
  const columns = [
    {
      field: 'docNumber',
      headerName: 'N° Doc',
      type: 'string',
      align: 'center',
      flex: 1,
    },
    {
      field: 'monthlyRate',
      headerName: 'Tasa mensual',
      align: 'center',
      renderCell: ({ row }) => (
        <TextField
          id={row.id}
          name="monthlyRate"
          value={row.monthlyRate}
          onChange={setRateRows}
        />
      ),
      flex: 1.5,
    },
    {
      field: 'retentionRate',
      headerName: 'Tasa de retención',
      align: 'center',
      renderCell: ({ row }) => (
        <TextField
          id={row.id}
          name="retentionRate"
          value={row.retentionRate}
          onChange={setRateRows}
        />
      ),
      flex: 1.5,
    },
    {
      field: 'defaultRate',
      headerName: 'Tasa de mora',
      align: 'center',
      renderCell: ({ row }) => (
        <TextField
          id={row.id}
          name="defaultRate"
          value={row.defaultRate}
          onChange={setRateRows}
        />
      ),
      flex: 1.5,
    },
    {
      field: 'dateToPay',
      headerName: 'Fecha de pago',
      align: 'center',
      renderCell: (params) => (
        <GridEditDateCell
          {...params}
          name="dateToPay"
          value={params.row.dateToPay}
          onChange={setDateRow}
        />
      ),
      flex: 2,
    },
  ];
  return columns;
};

export default useRateColumns;
