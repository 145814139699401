import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';

const SurplusForm = ({ operativeRequest, setParameters }) => {
  const [commission, _setCommission] = useState();
  const [surplusType, setSurplusType] = useState('abono');

  const setCommission = (value) => {
    _setCommission(value);
    setParameters([
      {
        key: 'surplus',
        value: surplusType === 'abono' ? -value : value,
      },
    ]);
  };

  const handleSurplusTypeChange = (event) => {
    setSurplusType(event.target.value);
    setParameters((prevParams) => [
      ...prevParams.filter((param) => param.key !== 'surplusType'),
      { key: 'surplusType', value: event.target.value },
    ]);
  };

  return (
    <Stack>
      <Typography variant="h5" textAlign="center">
        {operativeRequest.operativeRequestType?.name}
      </Typography>
      <FormControl component="fieldset" sx={{ marginTop: 2 }}>
        <FormLabel component="legend">Tipo de Excedente</FormLabel>
        <RadioGroup
          row
          aria-label="surplus-type"
          name="surplus-type"
          value={surplusType}
          onChange={handleSurplusTypeChange}
        >
          <FormControlLabel value="abono" control={<Radio />} label="Abono" />
          <FormControlLabel value="cargo" control={<Radio />} label="Cargo" />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" sx={{ marginTop: 2 }}>
        <FormLabel component="legend">Monto</FormLabel>
        <NumericFormat
          id="change-surplus"
          name="monthlyRate"
          value={commission}
          onValueChange={(values) => setCommission(values.value)}
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={0}
          fixedDecimalScale
          valueIsNumericString
          prefix="$"
          customInput={TextField}
          isAllowed={(values) => values.floatValue >= 0}
        />
      </FormControl>
    </Stack>
  );
};

SurplusForm.propTypes = {
  operativeRequest: PropTypes.shape({
    id: PropTypes.string,
    operativeRequestType: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    operativeRequestManagers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        invoice: PropTypes.shape({
          id: PropTypes.string,
          folio: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  setParameters: PropTypes.func.isRequired,
};

export default SurplusForm;
