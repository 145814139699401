import { useMutation } from '@apollo/client';
import { FilterAutocomplete } from '@fingo/lib/components/inputs';
import FingoStack from '@fingo/lib/components/stacks/FingoStack';
import { MAIL_LOG, SEND_MAILS } from '@fingo/lib/graphql';
import Mail from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const mailOptions = [
  { value: 'PENDING', label: 'Pendiente' },
  { value: 'INITIATED', label: 'En proceso' },
  { value: 'COMPLETED', label: 'Envíado' },
  { value: 'FAILED', label: 'Fallido' },
];

const RejectionMailActions = ({ mailFilters, selectionModel, setMailFilters }) => {
  const [sendMails] = useMutation(SEND_MAILS, { refetchQueries: [MAIL_LOG] });
  const handleClick = () => {
    sendMails({
      variables: {
        mailLogIds: selectionModel,
      },
    });
  };
  return (
    <FingoStack
      direction="column"
      alignItems="start"
    >
      <Typography variant="h5" color="primary">Emails por enviar</Typography>
      <Stack width="100%" direction="row" justifyContent="flex-end" alignItems="center" py={1} spacing={2}>
        <IconButton
          color="primary"
          onClick={handleClick}
          disabled={!selectionModel.length}
          sx={{ mx: 1 }}
        >
          <Mail />
        </IconButton>
        <Box width="15%">
          <FilterAutocomplete
            multiple={false}
            options={mailOptions}
            states={mailFilters}
            setStates={setMailFilters}
            filterName="status_Status"
            filterTitle="Estado de envío"
            inputSx={{ background: 'white' }}
          />
        </Box>
      </Stack>
    </FingoStack>
  );
};
RejectionMailActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mailFilters: PropTypes.object.isRequired,
  selectionModel: PropTypes.arrayOf([PropTypes.string]).isRequired,
  setMailFilters: PropTypes.func.isRequired,
};
export default RejectionMailActions;
